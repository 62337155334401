<template>
  <router-view></router-view>
</template>

<script>
import Vue from 'vue'
import router from './router'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
Vue.prototype.$user_api = 'https://rfkd.feianmo.com/user'

export default {
  name: 'App',
  data(){
    return {}
  },
  components: {
  },
	router,
}
</script>

<style>
</style>
