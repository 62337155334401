<template>
  <div>
    <div class="row" @click="changeAir">
      <h5 class="col-6">{{isAir ? '空运' : '海运'}}地址</h5>
      <div class="col-6">
        <button type="button" class="btn btn-outline-secondary btn-sm float-end">
          切换为{{isAir ? '海运' : '空运'}}
        </button>
      </div>
    </div>
    <div>收件人：{{isAir ? '金女士' : '姜女士'}}</div>
    <div>电话：17350990350</div>
    <div>
      地址：福建省泉州市石狮市祥芝镇古浮村3区36号转({{clientFullCode}}+{{
      form.phNumber.length>9 ? form.phNumber : '菲律宾电话'
      }})</div>
    <small style="font-size: 12px; color: rgb(244, 67, 54);">*将快递寄送到以上地点，我们根据快递单地址栏上的专属编号给您寄送到菲律宾指定地址.</small>
  </div>
</template>

<script>
export default {
  name:'ChineseAddress',
  data(){
    return {
    }
  },
  props:[
    'isAir', 'clientFullCode', 'form'
  ],
  methods:{
    changeAir(){
      this.$emit('changeAir')
    }
  },
  mounted(){
  }
}
</script>

<style>

</style>