import { render, staticRenderFns } from "./AddressPage.vue?vue&type=template&id=db9c477a&scoped=true&"
import script from "./AddressPage.vue?vue&type=script&lang=js&"
export * from "./AddressPage.vue?vue&type=script&lang=js&"
import style0 from "./AddressPage.vue?vue&type=style&index=0&id=db9c477a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db9c477a",
  null
  
)

export default component.exports