<template>
  <div class="container">
    <main>
    <div class="py-5 text-center">
      <img 
      class="d-block mx-auto mb-1" 
      src="/static/img/logo.png" 
      alt="" 
      width="100" style="max-width:20%;filter: opacity(80%);">
      <h2>润丰{{typeName}}</h2>
      <p class="lead mb-0">请登记您的运输方式和收货地址，并获取您的专属编号。</p>
      <p class="lead mt-0">国内发货前，填写专属编号到快递地址栏，方便我们辨认您的收货地址。</p>
      <p class="lead">
        <button 
        type="button" 
        data-bs-toggle="modal" 
        data-bs-target="#staticBackdrop"
        @click="isCheckPrice = true"
        class="btn btn-secondary fw-bold border-white">收费标准</button> 
        &nbsp; 
        <button 
        type="button" 
        @click="isAir = !isAir"
        class="btn btn-secondary fw-bold border-white">
          润丰{{isAir ? '海运' : '空运'}}
        </button> 
        &nbsp; 
        <router-link to="/" class="btn btn-secondary fw-bold border-white">返回首页</router-link>
      </p>
      <p class="lead">
        <a 
        href="#collapseExample"
        data-bs-toggle="collapse"
        role="button" 
        aria-expanded="false" 
        aria-controls="collapseExample"
        class="btn btn-text btn-sm">
        <svg style="color:#6c757d;" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-wechat" viewBox="0 0 16 16">
          <path d="M11.176 14.429c-2.665 0-4.826-1.8-4.826-4.018 0-2.22 2.159-4.02 4.824-4.02S16 8.191 16 10.411c0 1.21-.65 2.301-1.666 3.036a.324.324 0 0 0-.12.366l.218.81a.616.616 0 0 1 .029.117.166.166 0 0 1-.162.162.177.177 0 0 1-.092-.03l-1.057-.61a.519.519 0 0 0-.256-.074.509.509 0 0 0-.142.021 5.668 5.668 0 0 1-1.576.22ZM9.064 9.542a.647.647 0 1 0 .557-1 .645.645 0 0 0-.646.647.615.615 0 0 0 .09.353Zm3.232.001a.646.646 0 1 0 .546-1 .645.645 0 0 0-.644.644.627.627 0 0 0 .098.356Z"/>
          <path d="M0 6.826c0 1.455.781 2.765 2.001 3.656a.385.385 0 0 1 .143.439l-.161.6-.1.373a.499.499 0 0 0-.032.14.192.192 0 0 0 .193.193c.039 0 .077-.01.111-.029l1.268-.733a.622.622 0 0 1 .308-.088c.058 0 .116.009.171.025a6.83 6.83 0 0 0 1.625.26 4.45 4.45 0 0 1-.177-1.251c0-2.936 2.785-5.02 5.824-5.02.05 0 .1 0 .15.002C10.587 3.429 8.392 2 5.796 2 2.596 2 0 4.16 0 6.826Zm4.632-1.555a.77.77 0 1 1-1.54 0 .77.77 0 0 1 1.54 0Zm3.875 0a.77.77 0 1 1-1.54 0 .77.77 0 0 1 1.54 0Z"/>
        </svg>
        </a> 
        &nbsp; 
        <a 
        href="https://t.me/Rfeng0220"
        target="_black"
        class="btn btn-text btn-sm">
        <svg style="color:#6c757d;" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-telegram" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
        </svg>
        </a> 
      </p>
      <div class="collapse" id="collapseExample">
        <div class="card card-body">
          客服微信号：RuiF1588
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
           aria-expanded="true" aria-controls="collapseOne">
          第一步：登记地址
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div class="accordion-body">

          <form class="needs-validation" novalidate>
            <div class="row g-3">
            <div class="col-sm-6">
              <label class="form-label">菲律宾收件人</label>
              <input type="text" class="form-control" v-model="form.phName" required>
            </div>

            <div class="col-sm-6">
              <label class="form-label">菲律宾电话</label>
              <input type="text" class="form-control" v-model="form.phNumber" required>
            </div>

            <div class="col-12">
              <label class="form-label">菲律宾收件地址</label>
              <input type="text" class="form-control" v-model="form.phAddress" required>
            </div>
            
            <div class="col-6">
              <label class="form-label">运输方式</label>
              <select class="form-select" v-model="isAir">
                <option :value="false">海运</option>
                <option :value="true">空运</option>
              </select>
            </div>

            <div class="col-6">
              <label for="address" class="form-label">
                专属编号
              </label>
              <input type="text" class="form-control" :value="clientFullCode" disabled readonly required>
            </div>
            </div>
            <hr class="mb-1 mt-2" style="color:#ababab;">
            <div class="row">
              <div class="col-md-8 my-1">
                <div style="font-size:12px;color: #646464;">*专属编号由系统生成，无需修改，用于辨别包裹所属客户</div>
                <div style="font-size:12px;color: #f44336;">*专属编号在提交后生效，别忘了点击提交</div>
              </div>
              <div class="col-md-4 my-1">
                <button 
                type="button" 
                class="btn btn-primary float-md-end" 
                data-bs-toggle="modal" 
                data-bs-target="#staticBackdrop"
                @click="setAddress">提交</button>
              </div>
            </div>
            <div class="m-3"></div>
          </form>
          </div>
        </div>
        </div>
        <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
           aria-expanded="false" aria-controls="collapseTwo">
          第二步：国内发货
          </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div v-if="!isAlreadySet" class="alert alert-warning" role="alert">
              您目前未提交收货地址，当前专属编号({{clientFullCode}})还未生效，请提交后再使用以下信息寄送快递。
              <hr class="my-1" style="color:#ababab;">
              <button class="btn btn-secondary btn-sm mt-1 p-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
              aria-expanded="true" aria-controls="collapseOne">
              去提交地址
              </button>
            </div>
            <ChineseAddress @changeAir="changeAir" :form="form" :isAir='isAir' :clientFullCode='clientFullCode' />
          </div>
        </div>
        </div>
        <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree"
           aria-expanded="false" aria-controls="collapseThree">
          第三步：在菲收货
          </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <p class="my-1">在国内发送快递到指定地点后，我们根据快递单上的专属编号判断包裹所属的客户，我们将快递运输到菲律宾后，会给您配送到指定地点。</p>
            <p class="my-1">在此之前，希望您能了解我们以下这些收费标准。</p>
            <hr class="m-3" style="color:#ababab;">
            <ServePrice :isAir="isAir" @changeAir="changeAir" />
          </div>
        </div>
        </div>
      </div>
      </div>
    </div>
    </main>

    <footer class="my-5 pt-5 text-muted text-center text-small" style="font-size:12px;">
    <p>客服微信RuiF1588丨Telegram<a href="https://t.me/Rfeng0220" target="_black" class="text-primary">@Rfeng0220</a>丨Telegram频道<a target="_black" href="https://t.me/Runfeng0220"
       class="text-primary">@Runfeng0220</a>.</p>
    </footer>

    <!-- Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">通知</h5>
            <button type="button" @click="isCheckPrice = false" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="msg" class="alert alert-warning" role="alert">
              {{msg}}
            </div>
            <div class="mt-4">
              <ChineseAddress v-if="isAlreadySet" @changeAir="changeAir" :form="form" :isAir='isAir' :clientFullCode='clientFullCode' />
              <hr class="my-3" v-if="isCheckPrice && isAlreadySet" />
              <ServePrice v-if="isCheckPrice" :isAir="isAir" @changeAir="changeAir" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="isCheckPrice = false" class="btn btn-secondary" data-bs-dismiss="modal">关闭</button>
          </div>
        </div>
      </div>
    </div>
    <div v-html="pageCss"></div>
  </div>
</template>

<script>
import ServePrice from '../components/ServePrice'
import ChineseAddress from '../components/ChineseAddress'
import axios from 'axios'
import {nanoid} from 'nanoid'
export default {
  name:'AddressPage',
  data(){

    // 是否空运
    const isAir = this.$route.query.type === 'air'

    // 获取设备id
    let deviceId = localStorage.getItem('deviceId')
    if(!deviceId){
      deviceId = nanoid()
      localStorage.setItem('deviceId',deviceId)
    }
    return {
      isAir,
      isAlreadySet:false,
      isCheckPrice:false,
      deviceId,
      clientCode:null,
      form:{
        phAddress:'',
        phNumber:'',
        phName:'',
      },
      msg:'',
      pageCss:'<style>\
      body { \
        background-image: linear-gradient(\
          rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.7)), \
          url(https://upload.cc/i1/2022/10/24/PktiCs.jpg); \
        background-position: center top !important; \
        background-size: cover !important; \
        background-repeat: repeat, no-repeat; \
        background-attachment: fixed;\
      }\
      </style>'
    }
  },
  computed:{
    typeName(){
      if(this.isAir){
        return '空运'
      }else{
        return '海运'
      }
    },
    clientFullCode(){
      if(!this.clientCode){
        return ''
      }
      if(this.isAir){
        return 'FC' + this.clientCode.toString()
      }else{
        return 'GX' + this.clientCode.toString()
      }
    }
  },
  methods:{
    changeAir(){
      this.isAir = !this.isAir
    },
    setAddress(){
      this.msg = null
      this.isAlreadySet = false
      this.isCheckPrice = false

      const {
        deviceId, clientCode
      } = this
      const {
        phAddress, phNumber, phName
      } = this.form
    
      // 完整性校验
      const form = {deviceId, clientCode, phAddress, phNumber, phName}
      for(let n in form){
        if(!form[n] || form[n].length < 4){
          this.msg = '请填写菲律宾收件人、电话和地址'
          return
        }
      }

      // 提交数据
      axios.get(
        this.$user_api + '?action=setContact'
        + '&deviceId=' + deviceId
        + '&clientCode=' + clientCode
        + '&phAddress=' + phAddress
        + '&phNumber=' + phNumber
        + '&phName=' + phName
      )
      .then(({data})=>{
        if(!data.status){
          this.msg = '提交失败'
        }
        else if(data.status === 200){
          this.isAlreadySet = true
          this.isCheckPrice = true
          this.msg = '提交成功，请让国内的商家或亲戚朋友将你的快递寄送到以下地点，地址栏一定要写上你的专属编号。'
        }
        else if(data.msg){
          this.msg = data.msg
        }else{
          this.msg = '提交失败'
        }
      })
    }
  },
  mounted(){
    axios.get(this.$user_api + '?action=getCode')
    .then(({data})=>{
      this.clientCode = data.clientCode
    })
  },
  components:{
    ChineseAddress, ServePrice
  }
}
</script>

<style scoped>
.container {
  max-width: 600px;
}

body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}
</style>