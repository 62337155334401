<template>
  <div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
    <header class="mb-auto">
      <div>
        <h3 class="float-md-start mb-0">润丰速运</h3>
        <nav class="nav nav-masthead justify-content-center float-md-end">
          <router-link to="/" class="nav-link" active-class="active" aria-current="page">首页</router-link>
          <router-link to="/address?type=air" class="nav-link" active-class="active">空运</router-link>
          <router-link to="/address" class="nav-link" active-class="active">海运</router-link>
        </nav>
      </div>
    </header>
  
    <main class="pb-4 pt-0">
      
      <img 
      class="d-block mx-auto mb-3" 
      src="/static/img/logo.jpg" 
      alt="" 
      width="100" style="max-width:20%;filter: opacity(80%);">
      <p class="lead">
        润丰提供整合货运和合同物流解决方案，以支持您在菲律宾与客户和供应商的贸易。
        我们利用多年经验提供您所需的舱位，确保供应链运行无阻。
      </p>
      <p class="lead">
        请选择以下两种运输方式
      </p>
      <p class="lead">
        <router-link to="/address?type=air" class="px-5 btn btn-lg btn-secondary fw-bold border-white bg-white">润丰空运</router-link>
      </p>
      <p class="lead">
        <router-link to="/address" class="px-5 btn btn-lg btn-secondary fw-bold border-white bg-white">润丰海运</router-link>
      </p>
    </main>
  
    <footer class="mt-auto text-white-50">
      <p>客服微信RuiF1588丨Telegram<a target="_black" href="https://t.me/Rfeng0220" class="text-white">@Rfeng0220</a>丨Telegram频道<a target="_black" href="https://t.me/Runfeng0220" class="text-white">@Runfeng0220</a>.</p>
    </footer>
    <div v-html="pageCss"></div>
  </div>
</template>

<script>
export default {
  name:'HomePage',
  data(){
    return {
      pageCss:'<style>\
      body { \
        background-image: linear-gradient(\
          rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.75)), \
          url(https://upload.cc/i1/2022/10/24/PktiCs.jpg); \
        background-position: center top !important; \
        background-size: cover !important; \
        background-repeat: repeat, no-repeat; \
        --bs-bg-opacity: 1; \
        background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; \
        --bs-text-opacity: 1; \
        color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; \
        text-align: center !important; \
        height: 100% !important; \
        display: flex !important; \
      }\
      body { \
        text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5); \
        box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5); \
      } \
      html { \
        height: 100% !important; \
      }\
      </style>'
    }
  },
}
</script>

<style scoped>
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
	color: #333;
	text-shadow: none;
	/* Prevent inheritance from `body` */
}

/* * Base structure */

.cover-container {
	max-width: 42em;
}

/* * Header */
.nav-masthead .nav-link {
	padding: .25rem 0;
	font-weight: 700;
	color: rgba(255, 255, 255, .5);
	background-color: transparent;
	border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
	border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link+.nav-link {
	margin-left: 1rem;
}

.nav-masthead .active {
	color: #fff;
	border-bottom-color: #fff;
}

/* 以下来自head */
.bd-placeholder-img {
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

@media (min-width: 768px) {
	.bd-placeholder-img-lg {
		font-size: 3.5rem;
	}
}

.b-example-divider {
	height: 3rem;
	background-color: rgba(0, 0, 0, .1);
	border: solid rgba(0, 0, 0, .15);
	border-width: 1px 0;
	box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
	flex-shrink: 0;
	width: 1.5rem;
	height: 100vh;
}

.bi {
	vertical-align: -.125em;
	fill: currentColor;
}
</style>