<template>
  <div>
    <div class="row" @click="changeAir">
      <h5 class="col-4 mt-1">润丰{{isAir ? '空运' : '海运'}}</h5>
      <div class="col-8">
      <button type="button" class="btn btn-outline-secondary btn-sm float-end">
          查看{{isAir ? '海运' : '空运'}}价格
      </button>
      </div>
    </div>
    
    <div v-if="isAir">
      <small style="font-size: 12px; color: rgb(244, 67, 54);">
        *空运清关包税，3-6个工作日到货。
      </small>
      <table class="table table-striped" style="font-size:12px;">
      <thead>
        <tr>
          <th scope="col" width="30%">货物</th>
          <th scope="col" width="20%">价格</th>
          <th scope="col">批注</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>普货不带电产品</td>
          <td>600p/kg</td>
          <td>未满一公斤以一公斤计算</td>
        </tr>
        <tr>
          <td>敏感货</td>
          <td>750p/kg</td>
          <td>超功率产品（超过100瓦）、化妆品、食品类、仿牌、充电宝、纯电池、液体粉末、药品与酒水（超大功率，纯电池（需做绝缘），液体/粉末。</td>
        </tr>
        <tr>
          <td>特货</td>
          <td>客服报价</td>
          <td>电子烟 证件 蓝牙耳机 品牌 贵重首饰</td>
        </tr>
        <tr>
          <td>二手手机</td>
          <td colspan="2">1200p/台</td>
        </tr>
        <tr>
          <td>全新手机</td>
          <td colspan="2">2400p/台</td>
        </tr>
        <tr>
          <td>笔记本3kg内</td>
          <td>3500p/台</td>
          <td>建议打木箱并做保价。</td>
        </tr>
        <tr>
          <td>笔记本超过3kg</td>
          <td>3500p/台+600p/kg</td>
          <td>3kg以上的部分计重收费，600p/kg，建议打木箱并做保价。</td>
        </tr>
        <tr>
          <td>手机</td>
          <td colspan="2">500P/台</td>
        </tr>
        <tr>
          <td>违禁物</td>
          <td>一律不收</td>
          <td>身份证、电话卡、烟弹、香烟、银行卡账号、四件套、国际违禁品</td>
        </tr>
      </tbody>
      </table>
      <small style="font-size: 12px; color: rgb(244, 67, 54);">
        *空运物件抵达菲律宾仓库可以累计取件（比如：两批空运物件一起配送或者自取累计计算会比较划算）
目前疫情期间，只能到指定仓库自提或者打车到付。
      </small>
    </div>

    <div v-else>
      <small style="font-size: 12px; color: rgb(244, 67, 54);">
        *海运双清关两个礼拜左右到货。
      </small>
      <table class="table table-striped" style="font-size:12px;">
      <thead>
        <tr>
          <th scope="col" width="30%">货物</th>
          <th scope="col" width="20%">价格</th>
          <th scope="col">批注</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>普货</td>
          <td>150p/kg</td>
          <td>首重1Kg，所有包裹累计称重</td>
        </tr>
        <tr>
          <td>冻品类10公斤内</td>
          <td>200p/kg</td>
          <td>提前向客服申报</td>
        </tr>
        <tr>
          <td>冻品类超过10公斤</td>
          <td>250p/kg</td>
          <td>提前向客服申报</td>
        </tr>
        <tr>
          <td>电脑显示器</td>
          <td colspan="2">250p/kg</td>
        </tr>
        <tr>
          <td>电脑主机</td>
          <td colspan="2">3000p/台</td>
        </tr>
        <tr>
          <td>笔记本</td>
          <td colspan="2">2000p/个</td>
        </tr>
        <tr>
          <td>手机</td>
          <td colspan="2">500P/台</td>
        </tr>
        <tr>
          <td>平板电脑</td>
          <td colspan="2">800P/台</td>
        </tr>
        <tr>
          <td>手表</td>
          <td colspan="2">500P/台</td>
        </tr>
        <tr>
          <td>无线耳机</td>
          <td colspan="2">200P/副</td>
        </tr>
        <tr>
          <td>烟</td>
          <td colspan="2">150p/条</td>
        </tr>
      </tbody>
      </table>
    </div>
    <hr class="my-3" style="color:#ababab;">
    <div>
      <div>发货日：每周三和每周日</div>
      <div>WeChat：RuiF1588</div>
      <div>Telegram：<a href="https://t.me/Rfeng0220" class="text-primary">@Rfeng0220</a></div>
      <div>专属频道：<a href="https://t.me/Runfeng0220"
       class="text-primary">@Runfeng0220</a></div>
    </div>
  </div>
</template>
  
<script>
export default {
  name:'ServePrice',
  data(){
    return {
    }
  },
  props:[
    'isAir'
  ],
  methods:{
    changeAir(){
      this.$emit('changeAir')
    }
  }
}
</script>

<style>

</style>