import VueRouter from 'vue-router'
import HomePage from '../pages/HomePage'
import AddressPage from '../pages/AddressPage'

export default new VueRouter({
	routes:[
    {
      path:'/',
      component:HomePage //组件
    },
    {
      path:'/address',
      component:AddressPage, //组件
    },
	]
})